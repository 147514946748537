<template>
  <v-card :class="`fill-height border pa-5 pb-9 ${ !loading && 'pt-9' }`">
    <v-progress-linear
      v-if="loading"
        indeterminate
        color="primary"
        class="mb-3"
        rounded
        height="4"
    ></v-progress-linear>
    <section class="d-flex justify-space-between align-center"  :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'flex-row'">
      <h2 class="f24 fw600 poppins secondary-1--text mr-auto">
        {{ title }}
      </h2>
      <section class="ml-auto">
        <v-btn small dense text @click="today" color="secondary-1" class="">
          TODAY
        </v-btn>
        <v-btn small dense text @click="prev" color="secondary-1" class="">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn small dense text @click="next" color="secondary-1" class="">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-menu v-model="menu" :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn small v-on="on" dense text color="secondary-1" >
              <v-icon small>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-3" width="200">
            <v-checkbox
                dense
                value="course"
                label="Courses"
                v-model="filter.type"
                hide-details="auto"/>
            <v-checkbox
                dense
                value="assessment"
                label="Assessments"
                v-model="filter.type"
                hide-details="auto"/>
            <v-checkbox
                dense
                value="meeting"
                label="Meetings"
                v-model="filter.type"
                hide-details="auto"/>
            <v-btn small color="primary" block dense class="mt-2" @click="apply">
              APPLY
            </v-btn>    
          </v-card>
        </v-menu>
      </section>
    </section>
    <FullCalendar
      ref="fullCalendar"
      :options="eventss">
      <template #eventContent="arg">
        <div style="height:20px;">
          <v-menu
            open-on-hover
            bottom
            max-width="250"
            z-index="10"
          >
            <template v-slot:activator="{ on, attrs }">
              <div style="height:20px;" v-bind="attrs" v-on="on" class="text-truncate fw500">
                {{arg.event.title}}
              </div>
            </template>
            <v-card class="poppins pa-2" style="margin-left: 0px" width="250">
              <v-row dense>
                <v-col cols="1" class="mt-1">
                  <v-sheet :style="`background-color: ${arg.event.textColor}; width: 100%`" height="15" class="rounded-sm" />
                </v-col>
                <v-col class="fw500 text-wrap f14">
                  <div class="f11 poppins secondary-3--text text-capitalize">{{ arg.event.extendedProps.type }}</div>
                  {{arg.event.title}}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="1">
                  <v-icon size="15">mdi-calendar</v-icon>
                </v-col>
                <v-col class="d-flex flex-row f12 text-wrap mt-1">
                  <span v-if="arg.event.start"> {{ $dateFormat.mmDDyy(arg.event.start) }} </span>
                  <span v-if="arg.event.end"> -{{ $dateFormat.mmDDyy(arg.event.end) }} </span>
                </v-col>
              </v-row>
              <v-row dense v-if="arg.event.extendedProps.type === 'meeting'">
                <v-col cols="1">
                  <v-icon size="15">mdi-clock-outline</v-icon>
                </v-col>
                <v-col class="d-flex flex-row f12 text-wrap mt-1">
                  <span>   {{ $dateFormat.timeAndPeriod(arg.event.extendedProps.start_date +'T' +arg.event.extendedProps.time + ':00') }} </span>
                </v-col>
              </v-row>
              <v-btn 
                v-if="arg.event.extendedProps.type === 'meeting'"
                class="text-capitalize poppins f12 mt-2" 
                color="primary"
                block
                target="_blank" 
                :href="arg.event.extendedProps.join_url"
                >Join</v-btn>
              <v-btn 
                v-else
                class="text-capitalize poppins f12 mt-2" 
                color="primary"
                block
                @click="$router.push({ name: 'User Course Details', params: { id: arg.event.extendedProps.uuid}})"
                >{{ arg.event.extendedProps.type === 'assessment'  ? 'View Course' : 'View' }}</v-btn>
            </v-card>
          </v-menu>
        </div>
      </template>
    </FullCalendar>
  </v-card>
</template>

<script>
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    FullCalendar,
  },
  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'month',
      week: 'week',
      day: 'day',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'gre-darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    selectedMonth: null,
    selectedYear: null,
    loading: false,
    menu: false,
    title: '',
    filter: {
      type: []
    }
  }),

  mounted() {
    this.getCalendarEvents()
  },

  computed: {
    ...mapState('usr', {
      eventss(state){
        var vm = this;
        let _events = state.calendar
        let options = {
          plugins: [ dayGridPlugin, listPlugin, timeGridPlugin ],
          initialView: 'dayGridMonth',
          events: _events,
          headerToolbar: {
            left: '',
            center: '',
            right: ''
          },
          dayMaxEvents: true, // for all non-TimeGrid views
          views: {
            dayGridMonth: {
              dayMaxEvents: 3 // adjust to 6 only for timeGridWeek/timeGridDay
            }
          },
          contentHeight: 'auto',
          datesSet: function(info){
            let {start, end} = info
            const dateObjectStart = new Date(start);
            const dateObjectEnd = new Date(end);

            if (dateObjectEnd.getMonth() === 0) {
              vm._data.selectedMonth = 12
              vm._data.selectedYear = dateObjectStart.getFullYear();
            } else {
              vm._data.selectedMonth = dateObjectEnd.getMonth();
              vm._data.selectedYear = dateObjectEnd.getFullYear();
            }
          },
          // eventClick: function(info) {
          //   var eventObj = info.event;
          //   vm.$router.push({ name: 'User Course Details', params: { id: eventObj.extendedProps.uuid}})
          // },
          // eventMouseEnter: function(info) {
          //   console.log(info)
          // }
        }
        return options
      }
    }),

  },

  methods: {
    ...mapActions('usr', ['getCalendarEventsAction']),

    getCalendarEvents(){
      this.loading = true
      this.getCalendarEventsAction({ month: this.selectedMonth, year: this.selectedYear, ...this.filter}).finally(() => {
        this.loading = false
        const calendarApi = this.$refs.fullCalendar.getApi();
        this.title = calendarApi.view.title;
      })
    },

    prev() {
      // Handle previous button click
      this.$refs.fullCalendar.getApi().prev();
    },

    next() {
      // Handle next button click
      this.$refs.fullCalendar.getApi().next();
    },

    today() {
      // Handle today button click
      this.$refs.fullCalendar.getApi().today();
    },

    apply(){
      this.getCalendarEvents()
    }
    
  },
  watch: {
    selectedMonth(val){
      this.getCalendarEvents()
    },
    selectedYear(val){
      this.getCalendarEvents()
    }
  },
}
</script>

<style>
  .fc-toolbar-title {
    font-family: 'Poppins';
  }
  span.fc-icon.fc-icon-chevron-left::before {
    vertical-align: initial !important;
  }
  span.fc-icon.fc-icon-chevron-right::before {
    vertical-align: initial !important;
  }
  .fc .fc-popover {
    z-index: 1 !important;
  }
</style>
